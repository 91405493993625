const commonEN = {
  form: {
    input: {
      revtag: {
        placeHolders: [
          "Paste your @RevTag here",
          "Hey! You can send me money on Revolut by following this link: https://revolut.me/RevTag",
          "Paste your https://revolut.me/RevTag here",
          "Paste your RevTag here",
        ],
      },
    },
  },
};

export default commonEN;
