const landingIT = {
  hero: {
    heading: {
      title: "Guadagna facilmente una tariffa oraria più alta",
      hilight: "Rispetto al tuo avvocato",
    },
    trust: {
      heading: {
        title: "Unisciti a più di 1200 Saver soddisfatti 🚀",
        hilight: "Proprio come loro",
      },
      reviews: [
        {
          name: "Joe D.",
          title: "🇦🇲",
          content:
            "Social Saver ha reso tutto così semplice! Sto risparmiando sulle commissioni e guadagnando ogni settimana.",
          image: "https://placehold.co/120",
        },
        {
          name: "Fançois E.",
          title: "🇧🇸",
          content:
            "Incredibile!! Non ho lavorato nemmeno 20 minuti e ho guadagnato un bonus di 300$!",
          image: "https://placehold.co/120",
        },
        {
          name: "Sarah F.",
          title: "🇦🇷",
          content:
            "Il miglior guadagno extra che abbia mai trovato. Ora, non vedo l'ora di ottenere sempre più affiliati!",
          image: "https://placehold.co/120",
        },
      ],
    },
  },

  whatisit: {
    heading: {
      title: "🌟 Cosa otterrai usando Social Saver?",
      hilight: "Veloce, Facile, Redditizio",
    },
    pros: [
      "🤑 280 $ / ora* (Sì, più di quanto guadagni il tuo avvocato!)",
      "🧑‍💻 5 semplici passaggi, solo 12 minuti in totale",
      "🔄 Limite settimanale di ricezione di €280 – Quindi, prenota il tuo posto oggi!",
    ],
    bonus: {
      affiliation:
        "Guadagna 7,5$ per ogni trasferimento effettuato dal tuo amico che hai invitato",
      punchline:
        "Il tuo amico potrebbe anche comprarti un drink per dirti grazie",
    },
  },
  howto: {
    heading: {
      title: "🎉 Inizia in soli 5 semplici passaggi",
      hilight: "Meno di 12 minuti",
    },
    steps: [
      {
        content: "📝 Registrati su Social Saver",
        suffix: "~2 minuti, 5 clic",
      },
      {
        content: "📲 Condividi il tuo Revtag per ricevere pagamenti",
        suffix: "~2 minuti, 5 clic",
      },
      {
        content: "💰 Deposita USDT nel tuo account",
        suffix: "~2 minuti, 4 clic",
      },
      {
        content: "💱 Fai il tuo ordine",
        suffix: "~1 minuto, 2 clic",
      },
      {
        content: "✅ Conferma di aver ricevuto €280 e rilascia l'USDT",
        suffix: "1 minuto, 3 clic",
      },
    ],
    outro: {
      question: "E poi?",
      choice:
        "La scelta è tua: investi in un'altra crypto 😎 - Esci a cena 😋 - Oppure vai in palestra 💪.",
      share: "(Ehi! Non dimenticare di condividere una foto con noi 🫶)",
    },
  },
  statistics: {
    heading: {
      title: "❤️ Grazie ai nostri primi Saver",
      hilight: "Che ci hanno portato qui 📈",
    },
    stats: [
      {
        icon: "😎",
        title: "Happy Savers",
        value: 1200,
        counterSuffix: "+",
      },
      {
        icon: "€",
        title: "Paid Out",
        value: 2_100_000,
        counterSuffix: "+",
      },
      {
        icon: "＄",
        title: "Released",
        value: 1_900_000,
        counterSuffix: "+",
      },
      {
        icon: "🕐",
        title: "Average paid time",
        value: 24,
        counterSuffix: "min.",
      },
      // `### 1,245 ###
      // Happy Savers`,
      // `### €2,045,800 ###
      // Paid Out`,
      // `### 1,996,000+ ###
      // USDT Released`,
      // `### 24 minutes ###
      // Average Transfer Time`,
    ],
  },
  ctasaving: {
    heading: {
      title: "🔥 Stai ancora pagando commissioni quando puoi guadagnare?",
      hilight: "Inizia a risparmiare oggi!",
    },
  },
  cookbook: {
    heading: {
      title: "🧩 Il ricettario segreto",
      hilight: "Dietro la magia di Social Saver",
    },
    intro:
      "Ti rendiamo facile guadagnare con un sistema semplice ed efficiente",
    ingredients: [
      {
        content: "👥 Raggruppiamo più piccole transazioni USDT dagli utenti.",
        suffix: "(come te prima)",
      },
      {
        content:
          "📦 Queste transazioni vengono combinate in un singolo lotto da €280",
        suffix: "riducendo le commissioni di transazione",
      },
      {
        content:
          "🔓 Poi, rilasciamo l'USDT direttamente nel portafoglio onchain del pagatore.",
        suffix: "riducendo ulteriormente le commissioni di transazione",
      },
    ],
    outro: {
      explaination: "Ecco come mantieni più soldi in tasca! 🤑",
    },
  },
  estimation: {
    heading: {
      title: "💸 Vediamo quali saranno i tuoi guadagni mensili",
      hilight:
        "E cosa potrebbe fare un extra di 470$ per i tuoi piani natalizi?",
    },
    earnings: {
      caption: "",
      opportunity: {
        placeholders: [
          "Quanti Saver potresti aiutare?",
          "8 ? (+ $440/m)",
          "12 ? (+ $560/m)",
          "18 ? (+ $740/m)",
          "O anche di più...",
          "30 ? (+ $1100/m)",
        ],
      },
      headers: {
        scenario: "Scenario",
        earning: "Guadagni Mensili",
      },
      data: [
        ["Tu <br /><small>(solo)</small>", "$100"],
        ["Tu <br /><small>+ Il tuo partner</small>", "$230"],
        ["La tua coppia <br /><small>+ La coppia di 1 amico</small>", "$290"],
        ["La tua coppia <br /><small>+ La coppia di 4 amici</small>", "$470"],
      ],
    },

    outro: {
      title: "🏁 Pronto per cominciare? Non perdere questa occasione",
      hilight:
        "Clicca qui 👇 per iniziare e invita il tuo partner prima che lo facciano i tuoi amici 😎!",
      cta: "TESTO CTA QUI",
    },
  },
  faq: {
    heading: {
      title: "FAQ",
      hilight: "Domande Frequenti",
    },
    items: [
      {
        question: "Cos'è un Revtag",
        answer:
          "Un Revtag è il tuo identificatore unico su Social Saver, come un nome utente, che consente ad altri utenti di inviare pagamenti direttamente al tuo account. Pensalo come il tuo identificativo di pagamento personale.",
      },
      {
        question: "Come apro un account Revolut",
        answer:
          "Per aprire un account Revolut, scarica l'app Revolut dall'App Store o da Google Play. Segui i passaggi di registrazione e sarai pronto con un account in pochi minuti",
      },
      {
        question: "Come compro USDT",
        answer:
          "Per comprare USDT, puoi usare piattaforme popolari come Binance o Coinbase. Crea un account, completa eventuali verifiche necessarie, quindi cerca USDT nell'app per fare l'acquisto.",
      },
      {
        question: "Quali criptovalute posso vendere",
        answer:
          "Attualmente, Social Saver supporta principalmente transazioni USDT. Altre criptovalute potrebbero essere aggiunte in futuro.",
      },
      {
        question: "Perché non vengono accettate più criptovalute",
        answer:
          "La maggior parte dei nostri utenti preferisce vendere stablecoin dopo aver preso i profitti. Poiché le stablecoin offrono un valore prevedibile, è più facile liquidare senza preoccuparsi della volatilità. Per questo motivo, la nostra piattaforma si concentra principalmente su stablecoin, rendendo il processo più fluido e affidabile per tutti.",
      },
      {
        question: "Quali sono i limiti",
        answer:
          "Social Saver limita i trasferimenti a €280 per utente ogni settimana. Questo ci consente di mantenere i trasferimenti fluidi e garantire un servizio di qualità per tutti.",
      },
      {
        question: "Come funziona",
        answer:
          "È semplice! Social Saver raggruppa piccole transazioni USDT in un singolo lotto da €280, il che aiuta a ridurre le commissioni. Dopo aver depositato USDT, Social Saver trasferisce €280 sul tuo Revtag, quindi rilasci l'USDT nel portafoglio del compratore.",
      },
      {
        question: "Posso annullare un ordine",
        answer:
          "Sì, puoi annullare un ordine finché non è stato completato. Vai nelle impostazioni del tuo account o nella cronologia degli ordini, seleziona l'ordine e scegli Annulla.",
      },
      {
        question: "Posso prelevare i fondi",
        answer:
          "Assolutamente. Puoi prelevare i fondi su qualsiasi portafoglio o conto bancario collegato in qualsiasi momento. Controlla con il tuo provider di portafoglio per eventuali tempi o commissioni di prelievo specifici.",
      },
      {
        question: "Perché non posso ricevere i fondi",
        answer:
          "Se non ricevi i fondi, potrebbe esserci un problema con il tuo account, ad esempio l'account bancario non supportato o un errore nella transazione. Contatta il nostro supporto clienti per ricevere assistenza.",
      },
    ],
  },
  testimonial: {
    heading: {
      title: "🌐 Come fanno oltre 1200 Saver",
      hilight: "Metti la commissione TX nelle tue tasche",
    },
    reviews: [
      {
        name: "Michael R.",
        title: "🇨🇦",
        content:
          "Social Saver ha cambiato il mio reddito—guadagnare è stato facile e la velocità delle transazioni è impressionante! Il mio unico consiglio sarebbe avere l'ordine da 280€ impostato automaticamente ogni settimana. Quella funzionalità lo renderebbe davvero imbattibile!",
        image: "https://placehold.co/120",
      },
      {
        name: "Marie P.",
        title: "🇬🇷",
        content:
          "Social Saver è super facile da usare! Ho fatto il mio primo trasferimento in meno di 15 minuti. Ora, risparmio sulle commissioni e guadagno soldi extra ogni settimana solo condividendo con gli amici.",
        image: "https://placehold.co/120",
      },
      {
        name: "Jamal T.",
        title: "🇬🇧",
        content:
          "Non mi aspettavo di guadagnare così tanto! Ogni referral mi porta 10$ extra, e i passaggi sono così semplici che non ho avuto problemi a iniziare. Il miglior reddito extra che abbia trovato!",
        image: "https://placehold.co/120",
      },
      {
        name: "Sarah L.",
        title: "🇺🇸",
        content:
          "L'app è così semplice. Non ho dovuto capire nulla—solo pochi clic e sto guadagnando! Con qualche amico a bordo, i miei guadagni mensili continuano a crescere.",
        image: "https://placehold.co/120",
      },
      {
        name: "Lucas W.",
        title: "🇩🇰",
        content:
          "Social Saver ha trasformato il risparmio in una fonte di reddito passivo. E il bonus di affiliazione è un enorme vantaggio! Facile da usare, veloce da configurare, e i soldi extra rendono tutto più interessante.",
        image: "https://placehold.co/120",
      },
    ],
    outro: {
      title: "🕢 Ancora leggendo?",
      content:
        "Elaboriamo **1 trasferimento a settimana** per utente, quindi prima inizi, prima ti metti in fila per il prossimo.",
      cta: "TESTO CTA QUI",
    },
  },
};

export default landingIT;
