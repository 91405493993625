const landingRO = {
  hero: {
    heading: {
      title: "Câștigă mai ușor o rată orară mai mare",
      hilight: "decât avocatul tău",
    },
    trust: {
      heading: {
        title: "Alătură-te celor peste 1200 de Savers fericiți 🚀",
        hilight: "La fel ca ei",
      },
      reviews: [
        {
          name: "Joe D.",
          title: "🇦🇲",
          content:
            "Social Saver a simplificat totul! Economisesc la comisioane și câștig în fiecare săptămână.",
          image: "https://placehold.co/120",
        },
        {
          name: "François E.",
          title: "🇧🇸",
          content:
            "Incredibil!! Nici măcar nu am lucrat 20 de minute și am obținut un bonus de 300$!",
          image: "https://placehold.co/120",
        },
        {
          name: "Sarah F.",
          title: "🇦🇷",
          content:
            "Cel mai bun venit suplimentar pe care l-am găsit vreodată. Acum, abia aștept să aduc mai mulți afiliați!",
          image: "https://placehold.co/120",
        },
      ],
    },
  },

  whatisit: {
    heading: {
      title: "🌟 Ce obții cu Social Saver?",
      hilight: "Rapid, Ușor, Profitabil",
    },
    pros: [
      "🤑 280 $ / oră* (Da, mai mult decât câștigă avocatul tău!)",
      "🧑‍💻 5 pași simpli, doar 12 minute în total",
      "🔄 Limită săptămânală de primire de 280 € – Rezervă-ți locul azi!",
    ],
    bonus: {
      affiliation: "Câștigă 7.5$ pentru fiecare prieten referit",
      punchline:
        "Poate prietenul tău chiar te va invita la o băutură în semn de mulțumire",
    },
  },
  howto: {
    heading: {
      title: "🎉 Începe în doar 5 pași simpli",
      hilight: "Mai puțin de 12 minute",
    },
    steps: [
      {
        content: "📝 Înregistrează-te pe Social Saver",
        suffix: "~2 minute, 5 clicuri",
      },
      {
        content: "📲 Partajează-ți Revtag-ul pentru a primi plăți",
        suffix: "~2 minute, 5 clicuri",
      },
      {
        content: "💰 Depune USDT în contul tău",
        suffix: "~2 minute, 4 clicuri",
      },
      {
        content: "💱 Plasează comanda",
        suffix: "~1 minut, 2 clicuri",
      },
      {
        content: "✅ Confirmă că ai primit 280 € și eliberează USDT",
        suffix: "1 minut, 3 clicuri",
      },
    ],
    outro: {
      question: "Și apoi?",
      choice:
        "Alegerea este a ta: Investește în altă criptomonedă 😎 - Mergi la o cină 😋 - Sau fă-ți un abonament la sală 💪.",
      share: "(Și nu uita să împărtășești o poză cu noi 🫶)",
    },
  },
  statistics: {
    heading: {
      title: "❤️ Mulțumim Saverilor noștri de început",
      hilight: "Care ne-au adus aici 📈",
    },
    stats: [
      {
        icon: "😎",
        title: "Happy Savers",
        value: 1200,
        counterSuffix: "+",
      },
      {
        icon: "€",
        title: "Paid Out",
        value: 2_100_000,
        counterSuffix: "+",
      },
      {
        icon: "＄",
        title: "Released",
        value: 1_900_000,
        counterSuffix: "+",
      },
      {
        icon: "🕐",
        title: "Average paid time",
        value: 24,
        counterSuffix: "min.",
      },
      // `### 1,245 ###
      // Happy Savers`,
      // `### €2,045,800 ###
      // Paid Out`,
      // `### 1,996,000+ ###
      // USDT Released`,
      // `### 24 minutes ###
      // Average Transfer Time`,
    ],
  },
  ctasaving: {
    heading: {
      title: "🔥 Încă plătești comisioane când poți câștiga?",
      hilight: "Începe să economisești azi!",
    },
  },
  cookbook: {
    heading: {
      title: "🧩 Rețeta secretă",
      hilight: "Din spatele magiei Social Saver",
    },
    intro: "Îți oferim un sistem simplu și eficient pentru a obține profit",
    ingredients: [
      {
        content: "👥 Grupăm tranzacții mici de USDT de la utilizatori.",
        suffix: "(așa cum ai făcut și tu la început)",
      },
      {
        content: "📦 Aceste tranzacții sunt combinate într-un lot de 280 €",
        suffix: "reducând comisioanele",
      },
      {
        content:
          "🔓 Apoi, eliberăm USDT direct în portofelul onchain al plătitorului.",
        suffix: "reducând și mai mult comisioanele",
      },
    ],
    outro: {
      explaination: "Așa păstrezi mai mulți bani în buzunar! 🤑",
    },
  },
  estimation: {
    heading: {
      title: "💸 Să vedem ce câștig lunar poți obține",
      hilight:
        "și ce ar putea face un plus de 470 $ pentru planurile tale de Crăciun?",
    },
    earnings: {
      caption: "",
      opportunity: {
        placeholders: [
          "Câți Savers ai putea ajuta?",
          "8 ? (+ 440$/lună)",
          "12 ? (+ 560$/lună)",
          "18 ? (+ 740$/lună)",
          "Sau chiar mai mulți...",
          "30 ? (+ 1100$/lună)",
        ],
      },
      headers: {
        scenario: "Scenariu",
        earning: "Câștiguri lunare",
      },
      data: [
        ["Tu <br /><small>(singur)</small>", "$100"],
        ["Tu <br /><small>+ Partenerul tău</small>", "$230"],
        ["Cuplul tău <br /><small>+ 1 cuplu de prieteni</small>", "$290"],
        // ["Cuplul tău <br /><small>+ 2 cupluri de prieteni</small>", "$350"],
        ["Cuplul tău <br /><small>+ 4 cupluri de prieteni</small>", "$470"],
      ],
    },

    outro: {
      title: "🏁 Ești gata să începi? Nu rata ocazia!",
      hilight:
        "Click aici 👇 pentru a începe și pentru a-ți referi partenerul înaintea prietenilor 😎!",
      cta: "CTA TEXT HERE",
    },
  },
  faq: {
    heading: {
      title: "FAQ",
      hilight: "Întrebări frecvente",
    },
    items: [
      {
        question: "Ce este un Revtag?",
        answer:
          "Revtag este identificatorul tău unic pe Social Saver, ca un nume de utilizator, care permite altor utilizatori să trimită plăți direct în contul tău. Gândește-l ca pe un identificator personal pentru plăți.",
      },
      {
        question: "Cum îmi fac un cont Revolut?",
        answer:
          "Pentru a deschide un cont Revolut, descarcă aplicația Revolut din App Store sau Google Play. Urmează pașii de înregistrare și vei avea contul activat în câteva minute.",
      },
      {
        question: "Cum pot cumpăra USDT?",
        answer:
          "Poți cumpăra USDT de pe platforme populare precum Binance sau Coinbase. Creează un cont, completează verificările necesare, apoi caută USDT în aplicație pentru a face achiziția.",
      },
      {
        question: "Ce criptomonede pot vinde?",
        answer:
          "În prezent, Social Saver suportă în principal tranzacții cu USDT. Alte criptomonede pot fi adăugate pe viitor.",
      },
      {
        question: "De ce nu sunt acceptate mai multe criptomonede?",
        answer:
          "Majoritatea utilizatorilor noștri preferă să vândă stablecoins după ce realizează profituri. Cum valoarea stablecoin-urilor este previzibilă, le este mai ușor să retragă fără a se îngrijora de volatilitate. De aceea, platforma noastră se concentrează pe stablecoins, facilitând un proces mai sigur și mai fiabil pentru toți.",
      },
      {
        question: "Care sunt limitele?",
        answer:
          "Social Saver limitează transferurile la 280 € per utilizator pe săptămână. Acest lucru ne permite să menținem tranzacțiile eficiente și să asigurăm servicii de calitate pentru toată lumea.",
      },
      {
        question: "Cum funcționează?",
        answer:
          "Este simplu! Social Saver grupează tranzacțiile mici de USDT într-un lot de 280 €, ceea ce ajută la reducerea comisioanelor. După ce depui USDT, Social Saver transferă 280 € în Revtag-ul tău, apoi eliberezi USDT în portofelul cumpărătorului.",
      },
      {
        question: "Pot anula o comandă?",
        answer:
          "Da, poți anula o comandă atâta timp cât nu a fost completată. Mergi în setările contului sau în istoricul comenzilor, selectează comanda și alege Anulează.",
      },
      {
        question: "Pot să retrag fonduri?",
        answer:
          "Desigur. Poți retrage fondurile în orice portofel sau cont bancar conectat, oricând. Verifică la furnizorul de portofel pentru timpii sau comisioanele specifice de retragere.",
      },
      {
        question: "Mai ai întrebări?",
        answer:
          "Nu ezita să ne contactezi la contact@social-saver.com pentru asistență suplimentară.",
      },
    ],
  },

  testimonial: {
    heading: {
      title: "🌐 La fel ca cei peste 1200 de Savers",
      hilight: "Păstrează comisionul de tranzacție în buzunarul tău",
    },
    reviews: [
      {
        name: "Michael R.",
        title: "🇨🇦",
        content:
          "Social Saver a fost o schimbare majoră pentru veniturile mele — câștigul este simplu și viteza tranzacțiilor impresionantă! Singurul lucru pe care mi l-aș dori ar fi ca ordinea de 280 să fie setată automat în fiecare săptămână. Această funcție ar face-o de neîntrecut!",
        image: "https://placehold.co/120",
      },
      {
        name: "Marie P.",
        title: "🇬🇷",
        content:
          "Social Saver este foarte ușor de folosit! Am făcut primul transfer în mai puțin de 15 minute. Acum economisesc la comisioane și câștig bani suplimentari în fiecare săptămână doar prin a-l împărtăși cu prietenii.",
        image: "https://placehold.co/120",
      },
      {
        name: "Jamal T.",
        title: "🇬🇧",
        content:
          "Nu mă așteptam să câștig atât de mult! Fiecare referință îmi aduce un plus de 10$, iar pașii sunt atât de simpli încât nu am avut probleme în a începe. Cel mai bun venit suplimentar pe care l-am găsit!",
        image: "https://placehold.co/120",
      },
      {
        name: "Sarah L.",
        title: "🇺🇸",
        content:
          "Aplicația este atât de ușor de utilizat. Aproape că nu a trebuit să învăț nimic — doar câteva clicuri și câștig bani! Cu câțiva prieteni implicați, câștigurile mele lunare continuă să crească.",
        image: "https://placehold.co/120",
      },
      {
        name: "Lucas W.",
        title: "🇩🇰",
        content:
          "Social Saver a transformat economisirea într-o sursă de venit pasiv. Bonusul de afiliat este un mare avantaj! Simplu de utilizat, rapid de configurat, iar banii în plus fac totul să merite.",
        image: "https://placehold.co/120",
      },
    ],
    outro: {
      title: "🕢 Încă citești?",
      content:
        "Procesăm **1 transfer pe săptămână** per utilizator, așa că cu cât începi mai devreme, cu atât mai repede intri în linie pentru următorul transfer.",
      cta: "CTA TEXT HERE",
    },
  },
};

export default landingRO;
